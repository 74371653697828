<template>
  <div>
    <p class="primary--text text-caption ma-0">{{ descripcion }}</p>
    <p class="flex text-body-1 align-center mb-4">
      <v-icon color="primary">mdi-file-outline</v-icon>
      <span class="text-caption font-weight-light d-inline-block mx-4">{{
        moment(fecha).format("DD/MM/YYYY HH:mm a")
      }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "DocumentoContratoComponent",
  props: {
    nombre: {
      type: String,
      default: "",
    },
    descripcion: {
      type: String,
      default: "",
    },
    fecha: {
      type: String,
      default: "",
    },
  },
};
</script>
