<template>
  <v-card class="mb-4 pa-4">
      <p class="text-h6 secondary--text">Acta de liquidación de entrega</p>
      <v-btn color="secondary" class="px-2 py-2 mb-4" @click="showDialog = true">
        <v-icon color="#FFF !important" class="px-2">mdi-file-download-outline</v-icon>
        Generar acta de liquidación
      </v-btn>
    <v-row>
      <v-col cols="12" md="5">
        <v-file-input
          outlined
          label="Acta de liquidación"
          color="secondary"
          prepend-icon=""
          append-icon="$file"
          v-model="liquidacion_compra.acta_liquidacion"
          @input="$v.liquidacion_compra.acta_liquidacion.$touch()"
          @blur="$v.liquidacion_compra.acta_liquidacion.$touch()"
          :error-messages="archivoError"
        ></v-file-input>
      </v-col>
      <v-col>
        <div class="d-flex mt-3" style="gap: 16px;">
          <v-btn color="secondary" class="px-2 py-2" @click="guardarActa()" v-if="liquidacion_compra.acta_liquidacion">
            <v-icon color="#FFF !important" class="px-2">mdi-content-save-plus-outline</v-icon>
            Guardar Acta de liquidación
          </v-btn>
          <v-btn v-if="existeActaLiquidacion"class="px-2 py-2" color="secondary" @click.stop="visualizarPdf()">
            <v-icon color="#FFF !important" class="px-2">mdi-eye-outline</v-icon>
            Ver acta de liquidación
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" :max-width="800" persistent>
      <v-card class="py-6" style="overflow: hidden">
        <section class="pt-0">
          <v-card-title class="text-h5 secondary--text">Acta de liquidación</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      label="Fecha del acta *"
                      v-model="formattedDate"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="fechaActaErrors"
                      @blur="$v.acta_info.fecha_acta.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="acta_info.fecha_acta"
                    locale="es"
                    no-title
                    color="primary"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-menu
                  ref="hora"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      outlined
                      label="Hora del acta *"
                      v-on="on"
                      v-bind="attrs"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      v-model="acta_info.hora_acta"
                      :error-messages="horaActaErrors"
                      @blur="$v.acta_info.hora_acta.$touch()"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    format="24hr"
                    color="secondary"
                    full-width
                    v-model="acta_info.hora_acta"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-around">
            <v-btn outlined color="secondary" @click="resetInfo()">Cancelar</v-btn>
            <v-btn color="secondary" @click="generarActa()"
              >Generar acta de liquidación</v-btn
            >
          </v-card-actions>
        </section>
      </v-card>
    </v-dialog>
    <PdfModal
      :isOpen="modalVisualizacionPdfbierta" 
      :source-loadable="visualizacionPdf" 
      @on-visibility-change="handlePdfModalVisibility"
      filename="archivo-acta-liquidacion"
  />

  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import moment from "moment";
import { convertToFormData } from "@/utils/data";
import PdfModal from "@/components/PdfModal.vue";
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}


export default {
  name: "ActaLiquidacionComponent",
  components: { PdfModal },
  props: {
    onlyView: {
      type: String | Number,
      default: '0',
    },
    contrato:{
      type: Object
    }
  },
  validations: {
    liquidacion_compra: {
      acta_liquidacion: { 
        required, 
        fileNameValid, 
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
    acta_info: {
      fecha_acta: { required },
      hora_acta: { required },
    },
  },
  data: () => ({
    modalVisualizacionPdfbierta: false,
    visualizacionPdf: createLoadable(null),
    showDialog: false,
    menu: false,
    menu2: false,
    acta_info: {
      fecha_acta: null,
      hora_acta: null,
    },
  }),
  computed: {
    ...mapState("seguimientoOrden", ["liquidacion_compra"]),
    formattedDate() {
      if (this.acta_info.fecha_acta) {
        return moment(this.acta_info.fecha_acta).format("DD/MM/YYYY");
      } else return "";
    },
    archivoError() {
      const errors = [];
      if (!this.$v.liquidacion_compra.acta_liquidacion.$dirty) return errors;
      
      if (!this.contrato?.acta_liquidacion && !this.$v.liquidacion_compra.acta_liquidacion.required) {
        errors.push("El acta de liquidación es requerida");
      }
      !this.$v.liquidacion_compra.acta_liquidacion.fileNameValid &&
        errors.push("El acta de liquidación debe ser un PDF");
      !this.$v.liquidacion_compra.acta_liquidacion.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);  
      
      return errors;
    },
    fechaActaErrors() {
      const errors = [];
      if (!this.$v.acta_info.fecha_acta.$dirty) return errors;
      !this.$v.acta_info.fecha_acta.required &&
        errors.push("El campo fecha del acta es requerido");
      return errors;
    },
    horaActaErrors() {
      const errors = [];
      if (!this.$v.acta_info.hora_acta.$dirty) return errors;
      !this.$v.acta_info.hora_acta.required &&
        errors.push("El campo hora del acta es requerido");
      return errors;
    },
    existeActaLiquidacion(){
      return this.contrato?.acta_liquidacion
    }
  },
  methods: {
    resetInfo() {
      this.acta_info.fecha_acta = "";
      this.acta_info.hora_acta = "";
      this.$v.acta_info.$reset();
      this.showDialog = false;
    },
    async guardarActa() {
        const formData = convertToFormData({
          nombre_documento: this.liquidacion_compra.acta_liquidacion,
          nombre: this.liquidacion_compra.acta_liquidacion.name
        });
        const { status } =
          await this.services.ContratoService.guardarActaLiquidacion(
            this.$route.params.idContrato,
            formData
          );       
        if (status === 200) {
          this.pushAppMessage({
            message: "Registro actualizado con éxito",
            type: "success",
          });
          //this.downloadActa(data);
          this.$emit('acta-guardada', this.$route.params.idContrato);
          this.liquidacion_compra.acta_liquidacion = null;
          this.resetInfo();
        }
    },
    async generarActa() {
      this.$v.acta_info.$touch();

      if (!this.$v.acta_info.$invalid) {
        const { status, data } =
          await this.services.ContratoService.generarActaLiquidacion(
            this.$route.params.idContrato,
            this.acta_info
          );

        if (status === 200) {
          this.downloadActa(data);
          this.resetInfo();
        }
      }
    },
    downloadActa(data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `ActaLiquidación.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    async visualizarPdf() {
      this.modalVisualizacionPdfbierta = true;
      toggleLoadable(this.visualizacionPdf);
      const { data } = await this.services.ContratoService.visualizacionPdf(this.$route.params.idContrato);
      setLoadableResponse(this.visualizacionPdf, data, { isFile: true });
    },
    handlePdfModalVisibility(visible) {
        this.modalVisualizacionPdfbierta = visible;
    },
  },
};
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}
</style>
