<template>
  <v-card class="pa-4 mb-6">
    <p class="text-h6 secondary--text">
      Documentos del contrato
    </p>
    <!-- :nombre="item.nombre_archivo" -->
    <documento-contrato
      v-for="item of documentos"
      :key="item.id"
      :descripcion="item.tipo_contrato"
      :fecha="item.fecha_emision"
    />
  </v-card>
</template>

<script>
import DocumentoContrato from "./DocumentoContratoComponent.vue";
export default {
  name: "DocumentosContratoComponent",
  components: { DocumentoContrato },
  data: () => ({
    documentos: [
      {
        id: 1,
        nombre: "Documentos de solicitud",
        descripcion: "Específicaciones técnicas de los soliitado para el proceso de compra",
        fecha: new Date(),
      },
      {
        id: 2,
        nombre: "Resolución o acuerdo de resultados",
        descripcion: "Resolución o acuerdo de resultados",
        fecha: new Date(),
      },
      {
        id: 3,
        nombre: "Orden de compra y contrato",
        descripcion: "Orden de compra y contrato",
        fecha: new Date(),
      },
      {
        id: 4,
        nombre: "Ordenes de pedido",
        descripcion: "Ordenes de pedido",
        fecha: new Date(),
      },
      {
        id: 5,
        nombre: "Ordenes de cambio",
        descripcion: "Ordenes de cambio",
        fecha: new Date(),
      },
      {
        id: 6,
        nombre: "Actas de recepción",
        descripcion: "Actas de recepción",
        fecha: new Date(),
      },
      {
        id: 7,
        nombre: "Actas o informes de incumplimiento",
        descripcion: "Actas o informes de incumplimiento",
        fecha: new Date(),
      },
    ],
  }),
  methods: {
    async getDocumentosContrato() {
      const { status, data } =
        await this.services.ContratoService.getDocumentosContrato(
          this.$route.params.idContrato
        );
      if (status == 200) {
        this.documentos = data;
      }
    },
  },
  created() {
    this.getDocumentosContrato();
  },
};
</script>
